import { createContext, useContext } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Store from '@/stores/cart-store';

let store;

export const StoreContext = createContext();

export function useStore () {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error('useStore must be used within StoreProvider');
  }

  return context.store;
}

export function StoreProvider ({ children, initialState: fallbackData, path, pageProps }) {
  const { _store: store } = initializeStore(fallbackData, path, pageProps);
  const router = useRouter();

  useEffect(() => {
    store.hydrate(fallbackData, router.asPath);
  }, [router.isReady, fallbackData, router.asPath, store]);

  return <StoreContext.Provider value={{ store }}>{children}</StoreContext.Provider>;
}

function initializeStore (fallbackData = null, path = null, pageProps = null) {
  const _store = store ?? new Store();
  const isServer = typeof window === 'undefined';
  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.js` and `pages/ssr.js` for more details
    // _store.hydrate(fallbackData)
  // For SSG and SSR always create a new store
  if (isServer) {
    return { _store };
  }
  // Create the store once in the client
  if (!store) {
    store = _store;
  };

  // store.hydrate(fallbackData, path);

  return { _store };
}
