import config from '@/config';

const registerTrackingID = (id) => {
  if (id) {
    return `gtag('config', '${id}', {
      page_path: window.location.pathname,
    });`
  }
  return '';
};

const code = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  ${registerTrackingID(config.gaTrackingId)}
  ${registerTrackingID(config.adwordsConversionId)}
`;

export default code;
