const { valueOrNull, boolValue } = require('./components/utils');

module.exports = {
  rootUrl: process.env.NEXT_PUBLIC_ROOT_URL,
  assetsUrl: 'https://assets.bidali.com',
  paymentsApiUrl: process.env.NEXT_PUBLIC_PAYMENTS_API_URL,
  paymentsApiPublishableKey: process.env.NEXT_PUBLIC_PAYMENTS_API_PUBLISHABLE_KEY,
  blockchainApiUrl: process.env.NEXT_PUBLIC_BLOCKCHAIN_API_URL,
  commerceApiUrl: process.env.NEXT_PUBLIC_COMMERCE_API_URL,
  commerceWebUrl: process.env.NEXT_PUBLIC_COMMERCE_WEB_URL,
  commerceEnv: process.env.NEXT_PUBLIC_COMMERCE_ENV,
  commerceReferrerApiKey: process.env.NEXT_PUBLIC_COMMERCE_REFERRER_API_KEY,
  gaTrackingId: valueOrNull(process.env.NEXT_PUBLIC_GA_TRACKING_ID),
  adwordsConversionId: valueOrNull(process.env.NEXT_PUBLIC_ADWORDS_CONVERSION_ID),
  adwordsConversionLabel: valueOrNull(process.env.NEXT_PUBLIC_ADWORDS_CONVERSION_LABEL),
  facebookPixelId: valueOrNull(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID),
  facebookPixelLoggingEnabled: boolValue(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_LOGGING_ENABLED),
  redditAdvertiserId: valueOrNull(process.env.NEXT_PUBLIC_REDDIT_ADVERTISER_ID),
  bugsnagApiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  inMaintenanceMode: boolValue(process.env.NEXT_PUBLIC_IN_MAINTENANCE_MODE),
  debugLogging: boolValue(process.env.NEXT_PUBLIC_DEBUG_LOGGING),
  supportUrl: 'https://support.spend.bidali.com',
  supportEmail: 'support.spend@bidali.com',
  addYourBusinessUrl: 'https://webforms.pipedrive.com/f/2XDkDOjqONUu7rx7Qk5I54WHpBDOy5cvlydcajemhzrBLh1GNpu5rvXC2IQr05pvl',
  brandRequestUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSc5hB2M-FHzs4gIK8WOnKRytX8r5bEAIhlMml1picl92-JbUw/viewform',
  integrateBidaliUrl: 'https://bidali.pipedrive.com/leads/web-forms/V2ViRm9ybTphMDU4YWY2MC0wMmE4LTExZWItYjVkZC04ZGU3NTQ3NzU2NGQ',
  walletConnectProjectId: '0d5734b282a1c247b4fcea074f029afb',
  walletConnectMetadata: {
    name: 'Bidali Giftcards',
    description: 'Buy giftcards with cryptocurrencies and stablecoins',
    url: 'https://giftcards.bidali.com',
    icons: ['https://assets.bidali.com/company/bidali/icon.png'],
  },
  termsUrl: 'https://www.bidali.com/policies/terms',
  privacyPolicyUrl: 'https://www.bidali.com/policies/privacy',
  minChromeVersion: process.env.NEXT_PUBLIC_MIN_CHROME_VERSION || 80,
}
