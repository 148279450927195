import React, { Component } from 'react';
import Link from 'next/link';
import ErrorPage from 'next/error'
import styled from 'styled-components';
import { FaceSadTear } from '@styled-icons/fa-regular/FaceSadTear';
import Button from '@/components/button';
import Bugsnag from '@/bugsnag';

const Container = styled.div`
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
  margin-bottom: 300px;
`;

const SadFaceIcon = styled(FaceSadTear)`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 40px;
`;

export default class Page extends Component {

  static async getInitialProps (ctx) {
    const { err, locale = 'en'  } = ctx;
    if (err) {
      Bugsnag.notify(err);
    }
    return {
      ...(await ErrorPage.getInitialProps(ctx)),
      messages: require(`@/locales/${locale}.json`)
    };
  }

  render () {
    return <>
      <Container>
        <h1>Oops! Something Went Wrong</h1>
        <SadFaceIcon size={100} />
        <div>
          <Link href="/buy" passHref>
            <Button primary>Browse all products</Button>
          </Link>
        </div>
      </Container>
    </>;
  }
}
