import Head from 'next/head';
import { useTranslations } from 'next-intl';

import config from '@/config';
// TODO: Pull this from the config
const SITE_ROOT = config.rootUrl;

const DefaultHead = () => {
  const t = useTranslations('defaultHead');

  const defaults = {
    shareImage: 'https://assets.bidali.com/commerce/share-images/default.png',
    title: `Bidali | ${t('title')}`,
    description: t('description'),
    author: 'Bidali HQ',
    keywords: t('keywords'),
    lastBuilt: Date.now(),
    google: {
      name: 'Bidali',
      about: t('title'),
      description: t('description'),
      applicationCategory: 'Software',
      author: 'Bidali HQ'
    },
    facebook: {
      title: t('title'),
      description: t('description'),
      type: 'website',
      siteName: 'Bidali',
      updatedTime: (new Date()).toISOString(),
      admins: '517607527',
      page: '1748858885180222'
    },
    twitter: {
      handle: '@bidalihq',
      title: t('title'),
      description: t('description'),
      creator: '@bidalihq'
    }
  };

  return (
    <Head>
      <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport" />
      <title key="title">{defaults.title}</title>
      <meta name="description" content={defaults.description} key="description" />
      <meta name="keywords" content={defaults.keywords} key="keywords" />
      <meta name="author" content={defaults.author} key="author" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={defaults.twitter.handle} />
      <meta name="twitter:title" content={defaults.twitter.title} key="twittertitle" />
      <meta name="twitter:description" content={defaults.twitter.description} key="twitterdescription" />
      <meta name="twitter:creator" content={defaults.twitter.creator} />
      <meta name="twitter:image:src" content={defaults.shareImage} key="twitterimage" />

      <meta property="og:title" content={defaults.facebook.title} key="ogtitle" />
      <meta property="og:description" content={defaults.facebook.description} key="ogdescription" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SITE_ROOT} key="ogurl" />
      <meta property="og:image" content={defaults.shareImage} key="ogimage" />
      <meta property="og:site_name" content={defaults.facebook.siteName} />
      <meta property="og:updated_time" content={defaults.facebook.updatedTime} />
      <meta property="og:rich_attachment" content="true" />
      <meta property="fb:admins" content={defaults.facebook.admins} />
      <meta property="fb:pages" content={defaults.facebook.page} />

      <meta itemProp="name" content={defaults.google.name} />
      <meta itemProp="about" content={defaults.google.about} key="itempropdescription" />
      <meta itemProp="description" content={defaults.google.description} key="itempropdescription" />
      <meta itemProp="author" content={defaults.google.author} />
      <meta itemProp="image" content={defaults.shareImage} key="itempropimage" />
      <meta itemProp="applicationCategory" content={defaults.google.applicationCategory} />

      <link rel="preload" as="image" href="/images/stuff.webp" />
      <link rel="preload" as="image" href="https://assets.bidali.com/company/bidali/logo.png" />
    </Head>
  );
};

export default DefaultHead;
