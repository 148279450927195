import { darken, lighten, rgba } from 'polished';
import { css } from 'styled-components';

export const fonts = {
  primary: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
  title: `'Inter', sans-serif`,
  // other: `'Noto Sans JP', sans-serif`
};

export const human = {
  header: {
    fontFamily: fonts.title,
    fontSize: 32
  },
  title: {
    fontFamily: fonts.primary,
    fontSize: 24
  },
  subtitle: {
    fontFamily: fonts.primary,
    fontSize: 18
  },
  body: {
    fontFamily: fonts.primary,
    fontSize: 14
  }
};

export const brandColors = {
  primary: '#4B4DF1',
  secondary: '#4B4DF1',
  accent: '#E4517F',
  disabled: '#dedede'
};

export const maxContentWidth = '1200px';

export const breakpoints = {
  xs: 400,
  sm: 615,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 3000
};

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const minMedia = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const darkMode = '@media (prefers-color-scheme: dark)';

export const colors = {
  ...brandColors,
  background: {
    primary: '#F7F8FD',
    secondary: '#f3f3f3',
    accent: '#E9F1F7',
    warning: 'rgba(247, 197, 0, 0.15)',
    navbar: '#ffffff',
    card: '#FFFFFF'
  },
  typography: {
    primary: '#4C4C71',
    secondary: lighten(.125, '#4C4C71'),
    subtle: '#B2B3C4',
    inverted: '#F1F3F5',
    disabled: '#efefef'
  },
  status: {
    danger: '#EB3449',
    info: '#32C5FF',
    success: '#2DD8A4',
    warning: '#F2C94C'
  }
};

const theme = {
  name: 'light',
  fonts,
  maxContentWidth,
  colors,
  shadows: {
    primary: '0 4px 12px 0 rgba(74,144,226,0.08), 0 1px 2px 0 rgba(0,0,0,0.10)',
    // primary: '0 .125em .25em rgba(0, 0, 0, .125), 0 .5em 1em rgba(0, 0, 0, .05)',
    hover: '0 .25em .5em rgba(74,144,226, .085), 0 .75em 1.25em rgba(0, 0, 0, .05)',
    card: {
      default: 'rgba(0, 0, 0, 0.06) 0px 1px 3px 0px, rgba(0, 0, 0, 0.10) 0px 1px 2px 1px',
      hover: '0 .25em .5em rgba(0, 0, 0, .085), 0 .75em 1.25em rgba(0, 0, 0, .05)'
    }
  }
};

export default theme;
