import styled from 'styled-components';
import Image from 'next/image';

const Container = styled.ul`
  align-items: flex-end;
  display: flex;
  list-style: none;
  margin: 0 0 1em;
  padding: 0;
  justify-content: ${({ $justifyContent }) => $justifyContent};

  li + li {
    margin-left: 1em;
  }
`;

const SocialMediaProfiles = ({ justifyContent = 'flex-end', facebook, linkedin, twitter, telegram, medium, angelList, github }) => (
  <Container $justifyContent={justifyContent}>
    {!!facebook && <li><a href={facebook} rel="noopener" name="Bidali on Facebook"><Image
      unoptimized
      height={24}
      width={24}
      src="/images/social/facebook.svg"
      alt="Bidali on Facebook"
      style={{
        maxWidth: "100%",
        height: "auto",
        maxHeight: "24px"
      }} /></a></li>}
    {!!linkedin && <li><a href={linkedin} rel="noopener" name="Bidali on LinkedIn"><Image
      unoptimized
      height={24}
      width={24}
      src="/images/social/linkedin.svg"
      alt="Bidali on LinkedIn"
      style={{
        maxWidth: "100%",
        height: "auto",
        maxHeight: "24px"
      }} /></a></li>}
    {!!twitter && <li><a href={twitter} rel="noopener" name="Bidali on Twitter"><Image
      unoptimized
      height={24}
      width={24}
      src="/images/social/twitter.svg"
      alt="Bidali on Twitter"
      style={{
        maxWidth: "100%",
        height: "auto",
        maxHeight: "24px"
      }} /></a></li>}
    {!!telegram && <li><a href={telegram} rel="noopener" name="Bidali on Telegram"><Image
      unoptimized
      height={24}
      width={24}
      src="/images/social/telegram.svg"
      alt="Bidali on Telegram"
      style={{
        maxWidth: "100%",
        height: "auto",
        maxHeight: "24px"
      }} /></a></li>}
    {!!angelList && <li><a href={angelList} rel="noopener" name="Bidali on AngelList"><Image
      unoptimized
      height={24}
      width={24}
      src="/images/social/angel-list.svg"
      alt="Bidali on AngelList"
      style={{
        maxWidth: "100%",
        height: "auto",
        maxHeight: "24px"
      }} /></a></li>}
    {!!github && <li><a href={github} rel="noopener" name="Bidali on Github"><Image
      unoptimized
      height={24}
      width={24}
      src="/images/social/github.svg"
      alt="Bidali on Github"
      style={{
        maxWidth: "100%",
        height: "auto",
        maxHeight: "24px"
      }} /></a></li>}
    {!!medium && <li><a href={medium} rel="noopener" name="Bidali on Medium"><Image
      unoptimized
      height={24}
      width={24}
      src="/images/social/medium.svg"
      alt="Bidali on Medium"
      style={{
        maxWidth: "100%",
        height: "auto",
        maxHeight: "24px"
      }} /></a></li>}
  </Container>
);

export default SocialMediaProfiles;
