import feathers, { socketio } from '@feathersjs/client';
import io from 'socket.io-client';

export function createClient (url) {
  const options = { transports: ['websocket'], timeout: 20000 };
  const socket = io(url, options);
  const app = feathers();

  app.configure(socketio(socket, { timeout: 60000 }));
  app.socket = socket;

  return app;
}
