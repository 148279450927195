import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { darken } from 'polished';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { Tiles } from '@rebass/layout';

import Section from '@/components/section';
import SocialMediaProfiles from '@/components/social-media-profiles';
import config from '@/config';
import categories from '@data/cats';

const featuredCategories = [
  'restaurants-and-food',
  'entertainment',
  'travel',
  'house-and-home',
  'gaming',
  'clothing-and-accessories',
  'sports'
];

const Container = styled.div`
  background: ${props => darken(0.025, props.theme.colors.background.primary)};
`;

const Disclaimer = styled.div`
  color: ${props => props.theme.colors.typography.subtle};
  text-align: left;
  margin-bottom: 2em;
`;

const Copyright = styled.small`
  color: ${({ theme }) => theme.colors.typography.subtle};
  display: block;
  text-align: right;
`;

const LinkList = styled.ul`
  font-size: .85em;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  line-height: 2;
  list-style-type: none;
  text-align: left;

  a {
    color: ${props => props.theme.colors.typography.secondary};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const ListTitle = styled(ListItem)`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
`;

const Footer = () => {
  const t = useTranslations('footer');
  const cT = useTranslations('categories');
  const lT = useTranslations('languages');
  const router = useRouter();
  const [isHydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, [])

  return (
    <Container>
      <Section>
        <Disclaimer>
          <strong>{t('disclaimerTitle')}</strong>
          <p><small>{t('disclaimerContent')}</small></p>
        </Disclaimer>
        <Tiles columns={[1, 1, 3, 6]}>
          <div>
            <LinkList>
              <ListTitle>{t('service')}</ListTitle>
              {/* <ListItem><Link href="/brands"><a title="All available brands">All Brands</a></Link></ListItem> */}
              <ListItem><Link href="/countries" title="All supported countries">{t('allCountries')}</Link></ListItem>
              <ListItem><Link href="/currencies" title="Supported currencies">{t('currencies')}</Link></ListItem>
              <ListItem><a rel="noopener noreferrer" href="https://www.bidali.com/app?utm_campaign=crypto_customer_remarketing&utm_medium=giftcards_site&utm_source=giftcard_site&utm_content=app_download_cta_footer">{t('downloadApp')}</a></ListItem>
            </LinkList>
          </div>
          <div>
            <LinkList>
              <ListTitle>{t('help')}</ListTitle>
              <ListItem><a href={config.supportUrl} target="_blank" title="Support and frequently asked questions" rel="noopener noreferrer">{t('support')}</a></ListItem>
              <ListItem><Link href="/contact" title="Contact information">{t('contact')}</Link></ListItem>
              <ListItem><a href={config.brandRequestUrl} target="_blank" rel="noopener noreferrer">{t('requestBrand')}</a></ListItem>
            </LinkList>
          </div>
          <div>
            <LinkList>
              <ListTitle>{cT('title')}</ListTitle>
              {categories.filter(category => featuredCategories.includes(category.slug)).map(category => (
                <ListItem key={category.id}>
                  <Link href={`/shop/${category.slug}`} passHref>{cT(category.slug || category.name)}</Link>
                </ListItem>
              ))}
            </LinkList>
          </div>
          <div>
            <LinkList>
              <ListTitle>{t('partners')}</ListTitle>
              <ListItem><a href={config.addYourBusinessUrl} target="_blank" rel="noopener noreferrer">{t('addBusiness')}</a></ListItem>
              <ListItem><a href={config.integrateBidaliUrl} target="_blank" rel="noopener noreferrer">{t('integrate')}</a></ListItem>
            </LinkList>
          </div>
          <div>
            <LinkList>
              <ListTitle>{t('legal')}</ListTitle>
              <ListItem><a href="https://www.bidali.com/policies/terms" title="Bidali User Agreement" target="_blank" rel="noopener noreferrer">{t('terms')}</a></ListItem>
              <ListItem><a href="https://www.bidali.com/policies/privacy" title="Bidali Privacy Policy" target="_blank" rel="noopener noreferrer">{t('privacy')}</a></ListItem>
              <ListItem><a href="https://www.bidali.com/policies/cookies" title="Bidali Cookie Policy" target="_blank" rel="noopener noreferrer">{t('cookies')}</a></ListItem>
            </LinkList>
          </div>
          <div>
            <LinkList>
              <ListTitle>{lT('language')}</ListTitle>
              {router.locales.map(locale => (
                <ListItem key={locale}>
                  <a href={`/${locale === 'en' ? '' : locale}`}>{lT(locale)}</a>
                </ListItem>
              ))}
            </LinkList>
          </div>
        </Tiles>
        <SocialMediaProfiles
          angelList="https://angel.co/bidali"
          facebook="https://www.facebook.com/bidalihq"
          linkedin="https://www.linkedin.com/company/bidali"
          medium="https://medium.com/bidali"
          twitter="https://twitter.com/bidalihq" />
        {isHydrated && <Copyright>&copy; {new Date().getFullYear()} Bidali Inc.</Copyright>}
      </Section>
    </Container>
  );
};

export default Footer;
