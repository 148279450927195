import styled, { css } from 'styled-components';

import Loader from '@/components/loader';

const sizes = {
  default: 0.9,
  small: 0.75
};

const Container = styled.div`
  align-items: center;
  border-radius: 1.5em;
  box-sizing: border-box;
  color: ${props => props.$primary || props.$accent ? 'white' : props.theme.colors.primary};
  cursor: pointer;
  display: inline-flex;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1;
  outline: none;
  padding: 1em 1.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: transform ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  white-space: nowrap;
  will-change: transform, box-shadow;

  &:hover {
    box-shadow: ${props => props.theme.shadows.primary};
    text-decoration: none;
    transform: translateY(-.0625em);
  }

  ${({ $accent, $bordered, disabled, $ghost, $primary, size, theme }) => css`
    background-color: ${disabled
      ? theme.colors.background.secondary
      : $primary
        ? theme.colors.primary
        : $accent
          ? theme.colors.accent
          : $ghost
            ? 'transparent'
            : 'white'
    };
    border-color: ${$primary ? theme.colors.primary : 'white'};
    border: ${$bordered ? `1px solid ${theme.colors.primary}` : 'none'};
    font-size: ${sizes[size]}em;
  `}

  @-moz-document url-prefix() {
    span {
      transform: translateY(.0625em);
    }
  }
`;

const Spinner = styled.div`
  height: 1em;
  margin-right: 1em;
  width: 1em;
`;

const Button = ({
  accent,
  children,
  disabled = false,
  ghost,
  isLoading = false,
  onClick,
  primary,
  size = 'default',
  bordered = true
}) => {
  return (
    <Container
      $accent={accent}
      className="button"
      disabled={disabled}
      $ghost={ghost}
      onClick={!disabled ? onClick : null}
      $primary={primary}
      size={size}
      $bordered={bordered}
    >
      {isLoading && (
        <Spinner>
          <Loader color="currentColor" mode="inline" size={18} />
        </Spinner>
      )}
      <span>{children}</span>
    </Container>
  );
};

export default Button;
