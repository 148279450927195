import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { media } from '@/themes';
import { useStore } from '@/stores/store-provider';
import statuses from '@/statuses';

const StatusWidget = dynamic(() => import('./checkout-flow/status-widget'), { ssr: false });
const CartButton = dynamic(() => import('./cart-button'), { ssr: false });
const SupportButton = dynamic(() => import('./support-button'), { ssr: false });

const Container = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.background.navbar};
  box-shadow: ${props => props.theme.shadows.primary};
  display: flex;
  flex-direction: row;
  height: 4em;
  position: fixed;
  width: 100%;
  z-index: 20;

  .home & {
    background: linear-gradient(to bottom, rgba(255, 255, 255, .5), rgba(255, 255, 255, 0));
    box-shadow: none;
    position: absolute;
  }

  ${media.sm`
    @supports (backdrop-filter: blur(1.25em)) {
      backdrop-filter: blur(1.25em);
      background: ${props => props.theme.colors.background.navbar}CC;

      .home & {
        backdrop-filter: none;
      }
    }
  `};
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${props => props.theme.maxContentWidth};
  padding: 0 1em;
`;

const Logo = styled(Image)`
  ${media.sm`
    transform-origin: left center;
    transform: scale(.75);
  `};

  > span {
    display: block;
  }
`;

const Right = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const ToolbarContainer = styled.div`
  flex: 1;
`;

const NavBar = ({ pageProps }) => {
  const router = useRouter();
  const store = useStore();
  const slug = pageProps && pageProps.country
    ? pageProps.country.slug
    : pageProps && pageProps.initialBrand
      ? pageProps.initialBrand.country.slug
      : null;

  const url = slug ? `/buy/${slug}` : '/buy';
  const shouldShowStatusWidget = !router.pathname.includes('checkout') && store.charge && (store.charge.statusCode >= statuses.processing || store.paymentSubmitted);

  const handleClickStatus = () => {
    router.push('/checkout');
  };

  return (
    <Container>
      <InnerContainer>
        <Link
          href={url}
          alt="Buy giftcards and mobile topups with cryptocurrency"
          style={{ flexShrink: 0 }}>

          <Logo
            alt="Bidali"
            height={30}
            priority
            src="https://assets.bidali.com/company/bidali/logo.png"
            unoptimized
            width={128} />

        </Link>
        <ToolbarContainer id="main-toolbar" />
        {shouldShowStatusWidget &&
          <StatusWidget
            store={store}
            onClick={handleClickStatus}
            onDismiss={store.clearCart} />
        }
        <Right>
          <SupportButton />
          <CartButton />
        </Right>
      </InnerContainer>
    </Container>
  );
};

export default observer(NavBar);
