const templateCurrency = (string, nameReplacement, symbolReplacement) => {
  const templated = string.replace(/\{CURRENCY_NAME\}/ig, nameReplacement).replace(/\{CURRENCY_SYMBOL\}/ig, symbolReplacement);
  return templated;
};

const valueOrNull = (value) => {
  return value !== '' ? value : null;
};

const boolValue = (value) => {
  return value === 'true';
};

const webpIfSupported = (image) => {
  return process.browser && Modernizr.webpalpha ? image.replace('.jpg', '.webp') : image;
};

const getWebpImagePath = (image) => {
  return image.replace(/\.(png|jpg|jpeg)/i, '.webp');
};

module.exports = {
  boolValue,
  valueOrNull,
  getWebpImagePath,
  templateCurrency,
  webpIfSupported
}
