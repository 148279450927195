
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import getConfig from 'next/config';

import config from '@/config';

const { serverRuntimeConfig = {}, publicRuntimeConfig = {} } = getConfig() || {};

class DevErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}


let ErrBoundary = DevErrorBoundary;

// Don't log these errors
const errorsToIgnore = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.'
];
const apiKey = serverRuntimeConfig.BUGSNAG_API_KEY || publicRuntimeConfig.BUGSNAG_API_KEY;
const appVersion = serverRuntimeConfig.APP_VERSION || publicRuntimeConfig.APP_VERSION;

let client = {
  notify: (e, ...rest) => {
    console.log('NOTIFY', e, rest);
  },
  leaveBreadcrumb: (e, ...rest) => {
    console.log('LEAVE BREADCRUMB', e, rest);
  },
  setUser(...rest) {
    console.log('SET USER', rest);
  }
};

if (apiKey && process.env.CI !== 'true') {
  const options = {
    appVersion,
    releaseStage: config.commerceEnv,
    apiKey
  };

  Bugsnag.start({
    ...options,
    plugins: [new BugsnagPluginReact(React)],
    onError: event => {
      const err = event.errors[0];
      const msg = err.errorMessage;
      const shouldLog = !errorsToIgnore.includes(msg);
      return shouldLog;
    }
  });

  ErrBoundary = Bugsnag.getPlugin('react');
  client = Bugsnag;
}

export default client;
export const ErrorBoundary = ErrBoundary;
