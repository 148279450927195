import styled, { css } from 'styled-components';

import { media } from '@/themes';

const Container = styled.div`
  position: relative;

  ${({ $background, color, $inverted, $overflowHidden, theme }) => css`
    background-color: ${$background || 'inherit'};
    color: ${color
      ? color
      : $inverted
        ? theme.colors.typography.inverted
        : theme.colors.typography.primary
    };
    overflow: ${$overflowHidden ? 'hidden' : 'initial'};
  `};
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.maxContentWidth};
  text-align: ${props => props.$textAlign || props.theme.textAlign};
  padding: 5em 1.5em;

  ${media.sm`
    padding: 2em 1.5em;
  `};
`;

const Section = ({ background, children, className, color, inverted, overflowHidden, style, textAlign = 'center' }) => {
  return (
    <Container
      className={className}
      $background={background}
      $inverted={inverted}
      color={color}
      $overflowHidden={overflowHidden}>
      <Content style={style} $textAlign={textAlign}>{children}</Content>
    </Container>
  );
};

export default Section;
