import numeral from 'numeral';
import { countryCodeEmoji } from 'country-code-emoji';
import groupBy from 'lodash.groupby';
import platform from 'platform';
import { get } from 'lodash';

import currencies from '@data/enabled-currencies.json';
import config from '@/config';
import Bugsnag from '@/bugsnag';

const baseShareUrl = 'https://assets.bidali.com/commerce/share-images';

const SYMBOLS = {
  EUR: '€',
  GBP: '£',
  INR: '₹',
  RUB: '₽',
  CAD: '$',
  AUD: '$',
  USD: '$',
  MXN: '$',
  ARS: '$'
};

import copy from 'copy-to-clipboard';

export const copyToClipboard = copy;

export const shareImageForBrand = brand => {
  if (!brand) {
    return null;
  }
  return `${baseShareUrl}/brand?i=${encodeURIComponent(brand.imageUrl)}&country=${brand.countryId}&bg=${encodeURIComponent(brand.backgroundColor)}`;
};

export const shareImageForCurrency = currency => {
  if (!currency) {
    return null;
  }
  return `${baseShareUrl}/currency?p=${currency.protocol}&s=${currency.displaySymbol}`;
};

export const shareImageForCountry = countryId => {
  if (!countryId) {
    return null;
  }
  return `${baseShareUrl}/country?c=${countryId}`;
}

export const templateCurrency = (string, nameReplacement, symbolReplacement) => {
  const templated = string.replace(/\{CURRENCY_NAME\}/ig, nameReplacement).replace(/\{CURRENCY_SYMBOL\}/ig, symbolReplacement);
  return templated;
};

export const generateDescription = (currencies, t) => {
  const grouped = groupBy(currencies, 'servicePath');
  const excludeNetworkName = ['btc', 'ltc'];
  const cheapNetworks = ['xlm'];
  let text = '';

  const sanitizeAssetName = name => {
    return name
      .replace('Binance-Peg', '')
      .replace('(ERC20)', '')
      .replace('(BEP20)', '')
      .replace('(Binance Smart Chain)', '')
      .replace('(Stellar Network)', '');
  };

  Object.keys(grouped)
    .sort(key => !excludeNetworkName.includes(key))
    .sort()
    .forEach(key => {
      const assets = grouped[key];

      if (cheapNetworks.includes(key)) {
        assets.unshift({ displayName: t('spendCheaplyWith') });
      }

      text = `${text} ${assets.map((asset, i) => {
        const { displayName } = asset;

        if (i === assets.length - 1 && !excludeNetworkName.includes(key)) {
          return `${sanitizeAssetName(displayName)} ${t('onNetwork', { networkName: asset.network.name })}`;
        }

        if (excludeNetworkName.includes(key)) {
          return `${displayName},`;
        }

        return `${sanitizeAssetName(displayName)}`;
      }).join(', ')}`;
    });

  return text
    .trim()
    .replace(/ , /gi, ', ')
    .replace(/  /gi, ' ')
    .replace(/with,/gi, t('with'))
    .replace(/con,/gi, t('with'))
    .replace(/, Spend/gi, `. ${t('spend')}`)
    .replace(/, Gasta/gi, `. ${t('spend')}`)
    .replace('Litecoin,', 'Litecoin.');
};

export const formatForCurrency = ({ amount, currency }) => {
  const formattedAmount = numeral(amount).divide(100).format(`0,0[.]00`);
  return `${SYMBOLS[currency] || ''}${formattedAmount} ${currency}`;
};

export const flagForCountryCode = code => {
  if (code === 'AN') {
    return countryCodeEmoji('NL');
  }
  return countryCodeEmoji(code);
};

//TODO: Handle strings in here
export const pricesForProduct = product => {

  if (!product) {
    return null;
  }

  if (!product.allowedPricesInCents && !product.minPriceInCents && !product.maxPriceInCents) {
    return null;
  }

  let visiblePrices;
  if (!product.variablePrice) {
    visiblePrices = product.allowedPricesInCents;
  } else {
    const currs = ['CAD', 'EUR', 'AUD', 'GBP'];
    if (product.currency !== product.quoteCurrency && !currs.includes(product.currency)) {
      const diff = product.maxPriceInCents - product.minPriceInCents;
      const pricePercents = [0, 2.5, 5, 12.5, 25, 50, 75, 100];
      visiblePrices = pricePercents.map((percent) => {
        // We need to make sure its rounded to the nearest dollar
        const calculatedPrice = Math.floor(numeral(percent).divide(100).multiply(diff).add(product.minPriceInCents).divide(100).value()) * 100;
        return calculatedPrice;
      });
    } else {
      const min = Math.max(product.minPriceInCents, 500);
      visiblePrices = [
        500,
        1000,
        2000,
        5000,
        7500,
        10000,
        12500,
        15000,
        20000,
        25000,
        30000,
        40000,
        50000,
        75000,
        100000,
        125000,
        150000,
        200000
      ].filter(price => {
        const increment = numeral(product.increment).value();

        return (
          price >= min && price <= product.maxPriceInCents && (
            increment > 1
              ? price % numeral(increment).multiply(100).value() === 0
              : true
          )
        );
      });
    }
  }
  return visiblePrices;
};

export const getFallbackImage = type => {
  let fallbackImage = `https://assets.bidali.com/commerce/placeholders/phone.png`;
  switch (type) {
    case 'digitaltv':
      fallbackImage = `https://assets.bidali.com/commerce/placeholders/digitaltv.png`;
      break;
    case 'giftcard':
      fallbackImage = `https://assets.bidali.com/commerce/placeholders/giftcard.png`;
      break;
  }
  return fallbackImage;
};

export const getWebpImagePath = (image) => {
  return image.replace(/\.(png|jpg|jpeg)/i, '.webp');
};

export const parseIPAddress = req => {
  if (req.headers['x-forwarded-for']) {
    return req.headers['x-forwarded-for'].split(',')[0].trim();
  }
  return req.connection.remoteAddress;
};

export const getAllBrandsAndTopupsFromResults = results => {
  if (!results || !results.groups) return [];

  const { groups } = results;
  const brands = groups.reduce((acc, curr) => {
    if (curr.name === 'giftcard') {
      acc = acc.concat(curr.categories[0].brands)
    }

    if (curr.name === 'phone') {
      const topups = curr.categories.reduce((categoryAcc, currCategory) => {
        categoryAcc = categoryAcc.concat(currCategory.brands);

        return categoryAcc;
      }, []);

      acc = acc.concat(topups);
    }

    return acc;
  }, []);

  return brands;
};

export const loadScript = (src, callback) => {
  const script = document.createElement('script');
  let isReady = false;
  let target;

  script.type = 'text/javascript';
  script.src = src;
  script.onload = script.onreadystatechange = function () {
    if (!isReady && (!this.readyState || this.readyState == 'complete')) {
      isReady = true;
      callback();
    }
  };

  target = document.getElementsByTagName('script')[0];
  target.parentNode.insertBefore(script, target);
};

export const copyToClipboardCustom = value => {
  const input = document.createElement('input');

  input.value = value;
  input.style.position = 'absolute';
  input.style.zIndex = -1;
  input.setAttribute('readonly', '');
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, 99999);
  navigator.clipboard.writeText(input.value);
  document.body.removeChild(input);
};

export const calculatePaymentProgress = charge => {
  const currentPayment = charge && charge.payments[Object.keys(charge.payments)[0]];

  if (!currentPayment) {
    return { confirmations: 0 };
  }

  const requiredConfirmations = currentPayment.requiredConfirmations;
  const lastTransaction = currentPayment.transactions[currentPayment.transactions.length - 1];
  const confirmations = lastTransaction.confirmations <= requiredConfirmations ? lastTransaction.confirmations : requiredConfirmations;
  const progress = numeral(confirmations).divide(requiredConfirmations).multiply(100).value();

  return { confirmations, requiredConfirmations, progress };
};

export const clsx = (classNames) => Object.keys(classNames).filter(cls => classNames[cls]);

export const removeNullValues = obj => {
  let filteredObject = obj;

  if (obj && typeof obj === 'object') {
    const keysToFilterOut = Object.keys(obj).filter(key => obj[key] !== null);
    filteredObject = {};
    keysToFilterOut.map(key => { filteredObject[key] = obj[key]; });
  }

  return filteredObject;
};

export const sanitizeSourceMetadata = (source) => {
  const sanitizedSource = {};
  if (source) {
    Object.keys(source).map(key => {
      if (Array.isArray(source[key])) {
        sanitizedSource[key] = source[key].join(',');
      } else {
        sanitizedSource[key] = source[key];
      }
    });
    return sanitizedSource;
  } else {
    return source;
  }
};

export const getCurrencyByDisplaySymbol = displaySymbol => {
  if (!displaySymbol) return null;

  return currencies.find(currency => {
    if (config.commerceEnv !== 'production') {
      return currency.displaySymbol.toUpperCase() === displaySymbol.toUpperCase() && currency.protocol.startsWith('test');
    }

    return currency.displaySymbol.toUpperCase() === displaySymbol.toUpperCase();
  });
};

export const isUnsupportedBrowser = userAgent => {
  const UA = platform.parse(userAgent);
  const version = get(UA, 'version');
  const browserVersion = typeof version === 'string' && version.split('.')[0];
  let isUnsupported = false;

  Bugsnag.leaveBreadcrumb('UserAgent', { ua: userAgent });

  if (!browserVersion) {
    return false;
  }

  if (['chrome', 'chrome mobile'].includes(UA.name.toLowerCase()) && numeral(browserVersion).value() < numeral(config.minChromeVersion).value()) {
    isUnsupported = true;
  }

  if (UA.name.toLowerCase() === 'android browser') {
    const regex = /Chrome\/(\d+)/;
    const webViewVersion = UA.ua.match(regex);

    isUnsupported = numeral(webViewVersion[1]).value() < numeral(config.minChromeVersion).value();
  }

  return isUnsupported;
};

const excludedBrands = ['apple music', 'itunes', 'google', 'apple '];
export const shouldIncludeBrandOniOS = brand => {
  return !excludedBrands
    .some(excludedBrand => (
      brand.name
        .toLowerCase()
        .includes(excludedBrand)
    ));
};
