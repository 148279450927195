import GA from 'react-ga';
import config from '@/config';
import numeral from 'numeral';

const pixelOptions = {
  autoConfig: true,
  debug: config.facebookPixelLoggingEnabled,
};

export const initializeTracking = () => {
  if (window && window.location.hostname !== 'localhost') {
    if (config.gaTrackingId) {
      GA.initialize(config.gaTrackingId, { titleCase: false });
      GA.plugin.require('ec');
    }

    if (config.facebookPixelId) {
      FBPixel.init(config.facebookPixelId, {}, pixelOptions);
    }    
  }
};

const setAdvancedMatchingParams = (info) => {
  const advancedMatching = {
    em: info.email,
    fn: info.firstName,
    ln: info.lastName
  };
  if (config.facebookPixelId) {
    FBPixel.init(config.facebookPixelId, advancedMatching, pixelOptions);
  } else {
    // console.log('FacebookPixel init with advanced matching', advancedMatching);
  }
};

export const fbTrackEvent = (name, params) => {
  if (config.facebookPixelId) {
    FBPixel.track(name, params);
  } else {
    // console.log('FacebookPixel trackEvent', name, params);
  }
};

export const twitterTrackEvent = (name, params) => {
  if (window.twq) {
    twq('track', name, params);
  }
};

export const trackPageView = (page) => {
  if (window.ga && config.gaTrackingId) {
    GA.set({
      page
    });
    GA.pageview(page);
  } else {
    console.log('trackPageView', page);
  }
}

export const trackEvent = (params) => {
  if (config.gaTrackingId) {
    GA.event(params);
  } else {
    console.log('trackEvent', params);
  }
};

export const redditTrackEvent = (name) => {
  if (config.redditAdvertiserId && rdt) {
    rdt('track', name);
  } else {
    // console.log('RedditPixel trackEvent', name);
  }
};

const addProduct = (params) => {
  if (config.gaTrackingId) {
    GA.plugin.execute('ec', 'addProduct', params);
  } else {
    console.log('addProduct', params);
  }
};

export const trackAction = (action, params) => {
  if (config.gaTrackingId) {
    GA.plugin.execute('ec', 'addProduct', params);
    GA.plugin.execute('ec', 'setAction', action);
  } else {
    console.log('trackAction', action, params);
  }
};

const setAction = (action, params) => {
  if (config.gaTrackingId) {
    GA.plugin.execute('ec', 'setAction', action, params);
  } else {
    console.log('GA:ec:setAction', action, params);
  }
};

const trackConversion = (params) => {
  if (config.adwordsConversionId && config.adWordsConversionLabel && gtag) {
    gtag('event', 'conversion', params);
  } else {
    console.log('trackConversion', params);
  }
};

export const trackAddToCart = (brand, amount, quantity, product) => {
  trackAction('add', {
    id: product.id,
    name: product.name,
    category: brand.country.name, // Using country for a category
    brand: brand.name,
    price: numeral(amount).divide(100).format('0.00'),
    quantity: quantity,
    currency: product.currency
  });
  trackEvent({
    category: 'User',
    action: 'Clicked Add to Cart',
    label: `${brand.name} - ${product.name}`,
    value: numeral(amount).multiply(quantity).divide(100).value()
  });
  redditTrackEvent('AddToCart');
};

export const trackViewedBrandDetails = (brand) => {
  trackAction('detail', {
    id: brand.id,
    name: `${brand.name}`,
    category: brand.country.name, // Using country for a category
    brand: brand.name
  });
  const fbParams = {
    content_name: brand.name,
    content_category: brand.country.name,
    content_ids: [brand.id],
    content_type: 'product',
    referrer: document.referrer,
    userAgent: navigator.userAgent,
    language: navigator.language
  };
  fbTrackEvent('ViewContent', fbParams);
  redditTrackEvent('ViewContent');
};

const addProductsFromCart = (cart) => {
  // Call ec:addProduct for all items in cart
  cart.items.map(item => {
    addProduct({
      id: item.brand.id,
      name: `${item.product.name} - ${item.product.type}`,
      category: item.brand.country.name,
      brand: item.brand.name,
      price: numeral(item.amount).divide(100).format('0.00'),
      quantity: item.quantity,
      currency: item.product.currency
    });
  });
};

export const trackInitiateCheckout = (cart, source) => {
  addProductsFromCart(cart);
  setAction('checkout', { step: 1 });
  trackEvent({
    category: 'User',
    action: 'Clicked Checkout',
    label: source,
    value: cart.total
  });
  const fbParams = {
    value: cart.total,
    currency: cart.cartCurrency,
    contents: cart.items.map(item => ({
      id: item.product.id,
      quantity: item.quantity,
      item_price: numeral(item.amount).divide(100).value()
    })),
    num_items: cart.totalItems
  };
  fbTrackEvent('InitiateCheckout', fbParams);
};

export const trackSubmittedContactInfo = (cart, info) => {
  setAdvancedMatchingParams(info);
  addProductsFromCart(cart);
  setAction('checkout', { step: 2 });
  const fbParams = {
    value: parseFloat(cart.total),
    currency: cart.cartCurrency,
    contents: cart.items.map(item => ({
      id: item.brand.id,
      quantity: item.quantity,
      item_price: numeral(item.amount).divide(100).value()
    })),
    num_items: cart.totalItems
  };
  fbTrackEvent('AddPaymentInfo', fbParams);
  trackEvent({
    category: 'User',
    action: 'Submitted Contact Info',
    label: 'Next'
  });
};

export const trackPurchaseCompleted = (cart, charge, source) => {
  addProductsFromCart(cart);
  trackConversion({
    send_to: `${config.adwordsConversionId}/${config.adwordsConversionLabel}`,
    value: parseFloat(charge.amount),
    currency: charge.currency,
    transaction_id: charge.id
  });
  setAction('purchase', {
    id: charge.id,
    affiliation: 'Giftcards Website',
    revenue: numeral(charge.amount).format('0.00')
  });
  trackEvent({
    category: 'User',
    action: 'Transaction Detected',
    label: source,
    value: parseFloat(charge.amount),
    nonInteraction: true
  });
  const fbParams = {
    value: parseFloat(charge.amount),
    currency: charge.currency,
    contents: cart.items.map(item => ({
      id: item.product.id,
      quantity: item.quantity,
      item_price: numeral(item.amount).divide(100).value()
    })),
    num_items: cart.totalItems
  };

  fbTrackEvent('Purchase', fbParams);
  redditTrackEvent('Purchase');
  const twitterParams = {
    // required parameters
    value: `${charge.amount}`,
    currency: charge.currency,
    num_items: cart.totalItems,
    // optional parameters
    order_id: charge.id
  };
  twitterTrackEvent('Purchase', twitterParams);
};

// export default withTracker;
