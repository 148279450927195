import startCase from 'lodash.startcase';

const statuses = {
  pending: 100,
  underpaid: 105,
  processing: 200,
  success: 300,
  overpaid: 310,
  resolved: 320,
  failure: 400,
  timeout: 410,
  review: 417,
  unsettled: 415,
  refunded: 450,
  late: 420,
  error: 500
};

export const humanReadableStatuses = (() => {
  const value = {};

  Object.keys(statuses).forEach(status => {
    switch (status) {
      case 'underpaid':
        value[statuses[status]] = 'Partial Payment';
        break;
      case 'failure':
        value[statuses[status]] = 'General Failure';
        break;
      case 'unsettled':
        value[statuses[status]] = 'Unsettled';
        break;
      case 'late': {
        value[statuses[status]] = 'Paid Late';
        break;
      }
      case 'error': {
        value[statuses[status]] = 'Error';
        break;
      }
      default:
        value[statuses[status]] = startCase(status);
    }
  });

  return value;
})();

export default statuses;
